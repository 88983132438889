var Person = (function() {
    'use strict';

    var customer_id;

    // private

    // filters the person table on key press events
    var cbFilterPersonTable = function() {
        $('#id-filter-person').on("keyup", function() {
            var value = $(this).val().toLowerCase();
            $("#id-people-tbl tr").filter(function() {
                $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
            });
        });
    }

    // enable search only if an input is given
    var cbSearch = function() {
        var input = $('#id-search');
        var btn = $('#id-btn-search');

        btn.prop('disabled', input.val() == "");
        input.keyup(function() {
            btn.prop('disabled', input.val() ==  "");
        });
    }


    // public
    function start() {
        cbFilterPersonTable();
        cbSearch();
    }

    return {
        start: start
    };

}());

$(document).on('turbolinks:load', function() {
    Person.start();
});
