// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");

require("@nathanvda/cocoon");

import $ from 'jquery'
import popper from 'popper.js'
import bootstrap from 'bootstrap'

//import 'bootstrap-table/dist/bootstrap-table.js';
import "../stylesheets/application";
import typeahead from "typeahead.js";
import Bloodhound from "bloodhound-js";
import Chart from "chart.js";

import "mousetrap";
import "packs/mousetrap-global";

import s from "signature_pad"
window.SignaturePad = s
window.$ = window.jQuery = $;
window.bootstrap = bootstrap;
window.popper = popper;
window.toastr = require('toastr');

window.toastr.options = {
    "closeButton": true,
    "debug": false,
    "newestOnTop": true,
    "progressBar": false,
    "positionClass": "toast-bottom-right",
    "preventDuplicates": true,
    "onclick": null,
    "showDuration": "300",
    "hideDuration": "1000",
    "timeOut": "5000",
    "extendedTimeOut": "1000",
    "showEasing": "swing",
    "hideEasing": "linear",
    "showMethod": "fadeIn",
    "hideMethod": "fadeOut"
}

document.addEventListener("turbolinks:load", () => {
    // focus on the first text input field in the first field on the page
    $("input[type='text']:first", document.forms[0]).focus();
    //$('[data-toggle="tooltip"]').tooltip();
    //$('[data-toggle="tooltip"]').popover();
});

require("packs/dashboard");
require("packs/order");
require("packs/guard");
require("packs/typeahead");
require("packs/signature");
require("packs/shortcuts");
require("packs/person");
require("packs/scrollfix");
require("packs/login");
require("packs/bootstrap-datepicker.min");
require("packs/bootstrap-datepicker.de.min");

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)



