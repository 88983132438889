import Guard from "./guard";

var Dashboard = (function() {
    'use strict';

    // private
    var initGraph = function(ctx, type, title, labelData, valueData, bColor) {
        if (!ctx.length) {
            return;
        }
        var chart = new Chart(ctx, {
            type: type,
            data: {
                labels: labelData,
                datasets: [{
                    data: valueData,
                    fill: true,
                    borderColor: bColor,
                    lineTension: 0.1,
                }]
            },
            options: {
                legend: {
                    display: false
                },
                title: {
                    display: true,
                    text: title
                },
                scales: {
                    yAxes: [{
                        type: 'linear',
                        display: true,
                        position: 'left',
                        id: 'y-axis-orders'
                    }]
                }
            }
        });
    };

    var initOrders = function() {
        var ctx = $('#dashboard-chart-orders');
        var stats = $('#dashboard-chart-order-data');
        initGraph(ctx, 'line', "Aufträge der letzten 30 Tage", stats.data('statLabelsOrder'), stats.data('statValsOrder'), "#17a2b8");
    };

    var initSales = function() {
        var ctx = $('#dashboard-chart-sales');
        var stats = $('#dashboard-chart-order-data');
        initGraph(ctx, 'line', "Umsatz der letzten 30 Tage", stats.data('statLabelsOrder'), stats.data('statValsSales'), "#ffc107");
    };

    var initGraphs = function() {
        initOrders();
        initSales();
    };

    // public
    function start() {
        initGraphs();
    }

    return {
        start: start
    };

}());

$(document).on('turbolinks:load', function() {
    if (Guard.isNeeded('dashboard')) {
        Dashboard.start();
    }
});
