import consumer from "./consumer"

consumer.subscriptions.create("OrderChannel", {
    connected() {
        // Called when the subscription is ready for use on the server
        console.log("Connected to order channel");
    },

    disconnected() {
        // Called when the subscription has been terminated by the server
        console.log("Disconnected from order channel");
    },

    received(data) {
        console.log(data);
        var waypoint_json = jQuery.parseJSON(data.waypoint_json);
        // is order on this page?
        if(!$('#id-order-tr-' + waypoint_json.order_id).length) {
            console.log("NOT ON THIS PAGE");
            this.notifyUser(data);
            return;
        }
        // remember collapsed elements
        var collapsed = [];
        $('.collapse.show').each(function() {
            collapsed.push($(this).attr('id'));
        });

        // reload page asynchronly
        this.refreshAsync(data, collapsed);
    },

    refreshAsync(data, collapsed) {
        var that = this;
        $.ajax({
            type: "GET",
            url: "/orders.js" + window.location.search,
        }).fail(function(response) {
            toastr.error("Konnte Ansicht nicht aktualisieren");
        }).done(function(response) {
            that.notifyUser(data);
            jQuery.each(collapsed, function(idx, val) {
                $('#'+ val).collapse('show');
            });
        });
    },


    // popup info message for the dispo
    notifyUser(data) {
        var wp_json = jQuery.parseJSON(data.waypoint_json);
        var delivery_type = "Zustellung: ";
        if (wp_json.delivery_type == "pickup") {
            delivery_type = "Abholung: ";
        }
        var messenger = ""
        if (wp_json.hasOwnProperty('messenger')) {
            if (wp_json.messenger.hasOwnProperty('firstname')) {
                messenger += $.trim(wp_json.messenger.firstname);
            }
            if (wp_json.messenger.hasOwnProperty('lastname')) {
                messenger += " " + $.trim(wp_json.messenger.lastname);
            }
            messenger = $.trim(messenger);
        }
        var adr_name = "";
        if (wp_json.name) {
            adr_name = wp_json.name;
        }
        if (wp_json.address) {
            adr_name += ", " + wp_json.address;
        }
        toastr.info(delivery_type + adr_name, messenger);
    }
});
