import Guard from './guard';

var Login = (function() {
    'use strict';

    // private

    // directly submit on enter
    var submitOnEnter = function(wp) {
        $('#new_user .form-control').keyup(function(e) {
            if (e.which == 13) {
                $('#new_user').submit();
                return false;
            }
        });
    };

    // public
    function start() {
        submitOnEnter();
    }

    return {
        start: start
    };

}());

$(document).on('turbolinks:load', function() {
    if (Guard.isNeeded('devise/sessions')) {
        Login.start();
    }
});
