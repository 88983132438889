var Guard = (function() {
    'use strict';

    // public
    function isNeeded(controllerName) {
        var ctrl = $('body').data('controller');
        var result = ctrl.localeCompare(controllerName);
        console.log("Guard check: " + controllerName + " vs  " + ctrl + " ---> " + result);
        return result == 0;
    }

    return {
        isNeeded: isNeeded
    };
})();

export default Guard;
