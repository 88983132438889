var ShortCuts = (function() {
    'use strict';
    // private
    var gotoOrder = function() {
        window.location.href = "/orders";
    };
    var gotoOrderNew = function() {
        window.location.href = "/orders/new";
    };
    var addOrder = function() {
        // depending on where the user currently is
        // * anywhere -> add new order
        // * inside new order -> add new waypoint
        if ($('body').data('controller') == "orders" && $('body').data('action').match(/new|edit/g) ) {
            $('#id-add-waypoint').click();
            toastr.info("Ein neues Ziel wurde hinzugefügt.");
        } else {
            return gotoOrderNew();
        }
    };

    var removeLastWaypoint = function() {
        if (!$('.waypoint-address').length) {
            return;
        }
        let wp = $('.waypoint-address').last();
        if (!wp) {
            return;
        }
        let doit = false;
        let wp_val = wp.val();
        // find remove button
        let btn_rm = wp.parent().parent().parent().parent().find('.waypoint-remove');
        if (!btn_rm) {
            console.error("Remove button not found. Cannot remove waypoint");
            return;
        }
        if (!wp_val) {
            doit = true;
        } else {
            doit = confirm("Soll das Ziel " + wp_val + " entfernt werden?");
        }
        if (doit) {
            btn_rm.click();
            toastr.info("Das Ziel wurde gelöscht.");
        }
    };

    var gotoPerson = function(scope) {
        window.location.href = "/people/" + scope;
    };
    var gotoCustomer = function() {
        gotoPerson("customer");
    };
    var gotoMessenger = function() {
        gotoPerson("messenger");
    };
    var gotoDispo = function() {
        gotoPerson("dispo");
    };
    var focusSearch = function() {
        $('#search_term').focus();
        setTimeout((function() {
            $('#search_term').val('');
        }), 50);
    };
    var registerShortcuts = function() {
        Mousetrap.bind('g o', gotoOrder);
        Mousetrap.bind('g n', gotoOrderNew);
        Mousetrap.bindGlobal(['ctrl+1'], addOrder);
        Mousetrap.bindGlobal(['ctrl+2'], removeLastWaypoint);

        Mousetrap.bind('g c', gotoCustomer);
        Mousetrap.bind('g m', gotoMessenger);
        Mousetrap.bind('g d', gotoDispo);

        Mousetrap.bind("/", focusSearch);
    };

    // public
    function start() {
        registerShortcuts();
    }

    return {
        start: start
    };

}());

document.addEventListener("turbolinks:load", () => {
    ShortCuts.start();
});
