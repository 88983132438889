import Guard from './guard'

var signaturePad;

$(document).on('turbolinks:load', function() {
    if (Guard.isNeeded('orders')) {
        initSignaturePad();
        registerListener();
    }
});

var registerListener = function() {
    $('.signature-cancel').click(function() {
        signaturePad.clear();
    });
    $('.signature-clear').click(function(e) {
        // something to clear?
        if (signaturePad.isEmpty()) {
            e.preventDefault();
            return false;
        }

        if (!confirm("Unterschriftsfeld zurücksetzen und die Unterschrift entfernen?")) {
            e.preventDefault();
            return false;
        }
        signaturePad.clear();
    });
    $('.finish-without-sig').click(function(e) {
        if (!confirm("Ohne Unterschrift abschließen - wirklich fortfahren?")) {
            e.preventDefault();
            return false;
        }
        pickup();
    });
    $('.signature-save').click(function() {
        if (signaturePad.isEmpty()) {
            toastr.error("Keine Unterschrift vorhanden - bitte zuerst unterschreiben");
        } else {
            save(signaturePad.toDataURL());
        }
    });
};

var initSignaturePad = function() {
    // On mobile devices it might make more sense to listen to orientation change,
    // rather than window resize events.
    var canvas = document.querySelector("#signature_canvas");

    if (canvas) {
        canvas.width = 500;
        canvas.height = 300;
        window.onresize = resizeCanvas;
        resizeCanvas();
    }
};



// Adjust canvas coordinate space taking into account pixel ratio,
// to make it look crisp on mobile devices.
// This also causes canvas to be cleared.
var resizeCanvas = function() {

    // backup image
    var data;
    if (signaturePad) {
        data = signaturePad.toData();
    }

    var wrapper = document.getElementById("signature-pad");
    // PPMSig.clearButton = wrapper.querySelector("[data-action=clear]");
    //var changeColorButton = wrapper.querySelector("[data-action=change-color]");
    //var undoButton = wrapper.querySelector("[data-action=undo]");
    //var savePNGButton = wrapper.querySelector("[data-action=save-png]");
    //var saveJPGButton = wrapper.querySelector("[data-action=save-jpg]");
    //var saveSVGButton = wrapper.querySelector("[data-action=save-svg]");
    var canvas = wrapper.querySelector("canvas");
    signaturePad = new SignaturePad(canvas, {
        // It's Necessary to use an opaque color when saving image as JPEG;
        // this option can be omitted if only saving as PNG or SVG
        backgroundColor: 'rgb(245, 245, 245)'
    });


    // When zoomed out to less than 100%, for some very strange reason,
    // some browsers report devicePixelRatio as less than 1
    // and only part of the canvas is cleared then.
    var ratio =  Math.max(window.devicePixelRatio || 1, 1);

    // This part causes the canvas to be cleared
    canvas.width = canvas.offsetWidth * ratio;
    canvas.height = canvas.offsetHeight * ratio;
    canvas.getContext("2d").scale(ratio, ratio);

    // This library does not listen for canvas changes, so after the canvas is automatically
    // cleared by the browser, SignaturePad#isEmpty might still return false, even though the
    // canvas looks empty, because the internal data of this library wasn't cleared. To make sure
    // that the state of this library is consistent with visual state of the canvas, you
    // have to clear it manually.
    signaturePad.clear();

    if (data) {
        signaturePad.fromData(data);
    }
};

function pickup() {
    var sigPad = $('#signature-pad');
    $.ajax({
            type: "PATCH",
            url: "/waypoint/pickup/" + sigPad.data("id-waypoint"),
            data: {
                note: $('#wp_note_public').val(),
                displayDate: sigPad.data('display-date')
            }
        }).fail(function(response) {
            toastr.error("Zustellung konnte nicht gespeichert werden.");
        }).done(function(response) {
            signaturePad.clear();
        });
}

function save(sig_data) {
    var sigPad = $('#signature-pad');
    $.ajax({
            type: "POST",
            url: "/waypoint/sign/",
            data: {
                wid: sigPad.data("id-waypoint"),
                sig: sig_data,
                note: $('#wp_note_public').val(),
                displayDate: sigPad.data('display-date')
            }
        }).fail(function(response) {
            toastr.error("Unterschrift konnte nicht gespeichert werden.");
        }).done(function(response) {
            signaturePad.clear();
        });

}
