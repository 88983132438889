import Guard from './guard'

var Order = (function() {
    'use strict';

    // private

    // after the creation of a new waypoint, set the focus of
    // the cursor directly to the address input
    // if a messenger is selected in a previous address
    // set the messenger for every address DOWN the mask
    var cocoonCallbacks = function(wp) {
        // if the waypoint-remove button is directly clicked
        // the confirm method does not work - the event is
        // always propagated
        // --> hide the real button and use another one
        // to relay the action
        $('.waypoint-remove-wrapper').click(function(event)  {
            event.preventDefault();
            if (!confirm("Soll das Ziel entfernt werden?")) {
                return false;
            }
            $(this).parent().find('a.waypoint-remove').click();
        });

        wp.on('cocoon:after-insert', function(e, item) {
            // find last selected messenger and set the val
            $($('.waypoint-messenger').get().reverse()).each(function() {
                let s_val = $(this).val();
                // has a value
                if (s_val) {
                    item.find($('select.waypoint-messenger')).val(s_val);
                    return false; // break each
                }
            });
            // focus address
            item.find($('input.waypoint-address')).focus();
        });
    };

    // sets the messenger for every select input DOWN the mask
    // to the current choosen one, if the input is empty
    var selectMessengerListener = function() {
        //$('.waypoint-messenger').change(function(event) {
        $('.waypoint-messenger').blur(function(event) {
            let s_idx = $(this).data('idx');
            let s_val = $(this).val();
            if (!s_val) {
                return;
            }
            $('.waypoint-messenger').each(function(o_idx, obj) {
                // skip current
                if (o_idx == s_idx) {
                    return; // continue with next
                }
                // is a messenger already choosen? -> skip it
                let o_val = $(this).val();
                if (o_val) {
                    return; // continue with next
                }
                $(this).val(s_val);
            });
        });
    }

    var statusModalCb = function() {
        // bind data
        $('#orderStatusModal').on('show.bs.modal', function (event) {
            var button = $(event.relatedTarget);    // Button that triggered the modal
            var id_order = button.data('id-order');  // Extract info from data-* attributes
            var id_status = button.data('id-status');
            var modal = $(this);
            modal.find('.modal-title').text('Status ändern für Auftrag #' + id_order);
            $('#orderStatusSelect').val(id_status);
            $('#orderStatusModalIdOrder').val(id_order);
        });

        // focus on show
        $('#orderStatusModal').on('shown.bs.modal', function () {
            $('#orderStatusSelect').trigger('focus');
        });

        // ajax submit
        $('#orderStatusSelect').change(function(e) {
            $.ajax({
                type: "POST",
                url: "/orders/status/",
                data: {
                    oid: $('#orderStatusModalIdOrder').val(),
                    ostat: $(this).val()
                }
            }).fail(function(response) {
                toastr.error("Failed to set status");
            }).done(function(response) {
                toastr.success("Status changed");
                location.reload();
            });
        });
    }

    var customerModalCb = function() {
        $('#assign-customer-modal').on('show.bs.modal', function(event) {
            var button = $(event.relatedTarget);    // Button that triggered the modal

            // already loaded?
            if ($('#assign-customer-modal').data("id-order")) {
                console.log("already loaded. not loading again");
                // just focus the searchbox
                $('#assign-customer-modal-search').trigger('focus');
                return;
            }

            $("#assign-customer-modal").data("id-order", button.data('id-order'));
            console.log("Loading customer for order: " + $('#assign-customer-modal').data("id-order"));
            $(this).find('.modal-body').load(button.data("remote"), function() {
                // focus input
                $('#assign-customer-modal-search').trigger('focus');

                // prepare search filter
                $("#assign-customer-modal-search").on("keyup", function() {
                    var value = $(this).val().toLowerCase();
                    $("#assign-customer-modal-clist tr").filter(function() {
                        $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
                    });
                });
                $('.assign-customer').click(function(e) {
                    e.preventDefault();
                    $('#assign-customer-modal').modal('hide');
                    $.ajax({
                        type: "PATCH",
                        url: "/orders/customer/assign",
                        data: {
                            oid: $('#assign-customer-modal').data("id-order"),
                            cid: this.dataset.idcustomer
                        }
                    }).fail(function(response) {
                        toastr.error("Failed to set the customer");
                    });
                });
            });
        });

    }

    var messengerModalCb = function() {
        $('#assign-messenger-modal').on('show.bs.modal', function(event) {
            var button = $(event.relatedTarget);    // Button that triggered the modal
            $("#assign-messenger-modal").data("id-wp", button.data('id-wp'));
        });

        // if the modal is full shown
        // focus input upon modal opening
        $('#assign-messenger-modal').on('shown.bs.modal', function(event) {
            return $('#assign-messenger-modal-search').trigger('focus');
        });

        // prepare filter
        $("#assign-messenger-modal-search").on("keyup", function() {
            var value = $(this).val().toLowerCase();
            $("#assign-messenger-modal-mlist tr").filter(function() {
                $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
            });
        });


        $('.assign-messenger').click(function(e) {
            e.preventDefault();
            $('#assign-messenger-modal').modal('hide');
            $.ajax({
                type: "POST",
                url: "/orders/messenger/assign",
                data: {
                    wid: $('#assign-messenger-modal').data("id-wp"),
                    mid: this.dataset.idmessenger
                }
            }).fail(function(response) {
                toastr.error("Failed to set the messenger");
            });
        });
    }

    var toggleWaypointCb = function() {
        $("#id-collapse-all-wp").click(function() {
            var state = $(this).data('state');
            if (state == "collapsed") {
                $('.collapse').collapse("show");
                $(this).data('state', 'visible');
            } else {
                $('.collapse').collapse("hide");
                $(this).data('state', 'collapsed');
            }
        });
    }

    var datepickerCb = function() {
        var dp = $('#id-order-datepicker');
        var period = "days";
        var minMode = "days";
        if (dp.attr("data-period") == "month") {
            period = "months";
            minMode = "months";
        }
        dp.datepicker({
            startView: period,
            minViewMode: minMode,
            language: "de",
            daysOfWeekHighlighted: "0,6",
            todayHighligh: true,
            todayBtn: true
        }).on('changeDate', function(e) {
            $(this).datepicker('hide');
            var d = $(this).datepicker('getDate');
            var date = String(d.getDate()).padStart(2, '0');
            var month = String(d.getMonth()+1).padStart(2, '0');
            var year = d.getFullYear();
            var dateStr = year + "-" + month + "-" + date;

            var url = window.location.pathname + "?displayDate=" + dateStr;
            window.location.href = url
        });
    }

    var dismissMsgCb = function() {
        $('.dismiss-dispo-msg').on('click', function(e) {
            if (!confirm("Soll die Nachricht dauerhaft aus der Übersicht entfernt werden?")) {
                e.preventDefault();
                return false;
            }
            $.ajax({
                type: "PATCH",
                url: "/dispo_messages/dismiss/" + $(this).data('dispoMessageId')
            }).fail(function(response) {
                toastr.error("Fehler: Nachricht kann nicht unsichtbar gesetzt werden.");
            });
        });
    }

    // public
    function start() {
        cocoonCallbacks($('#id-waypoints'));
        //confirmNewCustomerListener();
        //disableSubmitEnterListener();
        selectMessengerListener();
        statusModalCb();
        messengerModalCb();
        customerModalCb();
        toggleWaypointCb();
        datepickerCb();
        dismissMsgCb();
    }

    return {
        start: start
    };

}());

$(document).on('turbolinks:load', function() {
    if (Guard.isNeeded('orders')) {
        Order.start();
    }
});
