import Guard from './guard'

// return an item if present, surrounded by prefix + suffix
var suggestion_item = function(item, prefix, suffix) {
    if (item) {
        return prefix + item + suffix;
    }
    return "";
}

// reset the feedback
var reset_feedback = function() {
    let container = $('#csearch-container');
    container.removeClass("background-color: bg-sugg-not-found");
    container.removeClass("background-color: bg-sugg-found");
    $('#csearch-hint').html("");
}

// display feedback if customer is found
var show_suggestion_feedback = function(item, query) {
    if (!query) {
        reset_feedback();
        return;
    }
    let container = $('#csearch-container');
    if (item > 0) {
        container.removeClass("background-color: bg-sugg-not-found");
        container.addClass("background-color: bg-sugg-found");
        $('#csearch-hint').html("Dieser Kunde ist bereits im System <strong>vorhanden</strong>.");
        $('#order_is_cash').prop("checked", false);
    } else {
        container.removeClass("background-color: bg-sugg-found");
        container.addClass("background-color: bg-sugg-not-found");
        $('#csearch-hint').html("Dieser Kunde wird <strong>neu</strong> angelegt.");
        $('#order_is_cash').prop("checked", true);
    }
}

// entry
document.addEventListener("turbolinks:load", () => {
    if (!Guard.isNeeded('orders')) {
        return;
    }

    let typeahead = $("#order_csearch"); // handle for typeahead obj
    let curval = typeahead.val();

    // do not submit the form on pressing enter after searching
    $('form input:not([type="submit"])').keydown(function(event) {
        if(event.which == '13'){
            event.preventDefault();
            $(this).next(':input').focus();
            return false;
        }
    });

    // upon selection focus next element
    typeahead.bind('typeahead:select', function(ev, suggestion) {
        $('#order_cid').val(suggestion.id);
        $('#order_waypoints_attributes_0_name').focus();
        if (suggestion.payment_option) {
            $('#order_cash').val(String(suggestion.payment_option.cash));
            $('#order_receipt').prop("checked", suggestion.payment_option.receipt);
            $('#order_receipt_email').val(suggestion.payment_option.receipt_email);
        }
    });

    // upon close focus next element
    typeahead.bind('typeahead:close', function(ev) {
        show_suggestion_feedback($('#order_cid').val(),  $('#order_csearch').val());
    });

    // configure suggestion engine
    let Bloodhound = require('bloodhound-js');
    let customers = new Bloodhound({
        datumTokenizer: Bloodhound.tokenizers.obj.whitespace('shortname', 'lastname', 'firstname', 'detail', 'comment'),
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        //prefetch: '../orders/customer',
        remote: {
            url: '../orders/customer?q=%QUERY',
            wildcard: '%QUERY'
        }
    });
    typeahead.typeahead({
        hint: true,
        highlight: true,
        minLength: 2
    },
    {
        name: 'customer',
        source: customers,
        display: function(c) {
            return c.lastname;
        },
        templates: {
            notFound: function(item) {
                if (!item.query) {
                    return;
                }
                $('#order_cid').val(0);
                typeahead.typeahead('close');
            },
            suggestion: function(item) {
                let firstname = suggestion_item(item.firstname, "", ", ");
                let lastname = "<strong>" + item.lastname+ "</strong>";
                let shortname = suggestion_item(item.shortname, "<small> (", ")</small>");
                return '<div>' + firstname + lastname + shortname + '</div>';
            }
        }
    })
    show_suggestion_feedback($('#order_cid').val(), $('#order_csearch').val());
    if (curval) {
        typeahead.typeahead('val', curval);
        typeahead.typeahead('close');
    }
});


